<template>
  <company-phone />
</template>
<script>
import CompanyPhone from "../../../components/App/Company/Companyphone.component.vue";

export default {
  components: {
    CompanyPhone,
  },
};
</script>
